import Categoryindex from '@/components/category/index';
import Consultation from '@/components/category/consultation';
import Categoryinfo from '@/components/category/info'
const base = [
    {
    path: 'category/index',
    component: Categoryindex
    },
    {
        path: 'consultation',
        component: Consultation
    },
    {
         path: 'category/info',
         component: Categoryinfo
    },
]
export default base;