import {
    POST
} from '@/services/request.js'

export default {
    productIndex(data = {}) {
        return POST({
            url: '/product/index',
            params: data
        })
    },
    productList(data = {}) {
        return POST({
            url: '/product/tabulation',
            params: data
        })
    },
    productInfo(data = {}) {
        return POST({
            url: '/product/info',
            params: data
        })
    },
    productCollect(data = {}) {
        return POST({
            url: '/product/collect',
            params: data
        })
    },
}