import React from 'react';
import cookie from 'react-cookies'
export default function LeaseRecovery() {
    let navigate = React.$Router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
    // 初始化路由参数
    const [leaseid, setLeaseid] = React.useState(searchParams.get("leaseid"))

    const [info, setInfo] = React.useState({
        product: {}
    })
    const [expresslist, setExpressList] = React.useState([])
    const [form] = React.$Vant.Form.useForm()
    const [isShow, setIsShow] = React.useState(false)
    const [expressid, setExpressid] = React.useState('')
    const [expresstext, setExpressText] = React.useState('')

    const getData = async () => {
        let result = await React.$Api.leaseInfo({ leaseid: leaseid, busid: LoginUser.id })

        if (result && result.code == 1) {
            setInfo(result.data)
        }

        let res = await React.$Api.express()
        if (res && res.code == 1) {
            setExpressList(res.data)
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    // 物流公司确认事件
    const onConfirmEexpress = (index,val) => {
        setIsShow(false)
        setExpressText(val.text)
        setExpressid(val.value)
    }

    // 表单提交
    const onFinish = async(values) => {
        let data = {
            leaseid: leaseid, 
            busid: LoginUser.id,
            busexpid: expressid,
            busexpcode: values.busexpcode
        }

        if(!expressid){
            React.$Vant.Toast.fail('请选择快递公司')
            return false;
        }
        let result = await React.$Api.leaseRecovery(data)
        if(result && result.code == 1)
        {
            React.$Vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/lease/index')
                }
            })
        } else {
            React.$Vant.Toast.fail(result.msg)
        }
    }

    return (
        <>
            <React.$Vant.NavBar
                title="归还商品"
                leftText=""
                onClickLeft={() => navigate('/lease/index')}
            />

            <div className="zy_module_content" style={{ paddingTop: 0 }}>
                <div className="swiper-container vip_user_order">
                    <div className="swiper-slide">
                        <ul>
                            <li style={{ borderBottom: 'none' }}>
                                <div className="vip_order_goods">
                                    <h3>
                                        <a href="my_dd_xq.html">
                                            <i><img src={info.product.cover_cdn} /></i>
                                            <div style={{
                                                float: 'right', marginLeft: '2.5%',
                                                width: '70%', fontSize: '.22rem', color: '#999', lineHeight: '25px'
                                            }}>
                                                <div>
                                                    <em>厂家联系人：</em>
                                                    <em>{info.contact}</em>
                                                </div>
                                                <div>
                                                    <em>厂家联系电话：</em>
                                                    <em>{info.mobile}</em>
                                                </div>
                                                <div>
                                                    <em>归还地址：</em>
                                                    <em>{info.address}</em>
                                                </div>
                                            </div>
                                        </a>
                                    </h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="lianxi_ge">
                <a href="#">联系客服</a>
            </div>

            <React.$Vant.Form
                form={form}
                onFinish={onFinish}
            >
                <React.$Vant.Form.Item
                    isLink
                    label='快递公司'
                    onClick={() => setIsShow(true)}
                >
                    <React.$Vant.Input value={expresstext} readOnly placeholder='请输入快递公司' />
                </React.$Vant.Form.Item>

                <React.$Vant.Popup visible={isShow} onClose={() => setIsShow(false)} position='bottom'>
                    <React.$Vant.Picker
                        columns={expresslist}
                        onConfirm={onConfirmEexpress}
                        onCancel={() => setIsShow(false)}
                    >
                    </React.$Vant.Picker>
                </React.$Vant.Popup>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写快递单号' }]}
                    name='busexpcode'
                    label='快递单号'
                >
                    <React.$Vant.Input placeholder='请输入快递单号' />
                </React.$Vant.Form.Item>
                <div className="zy_goods_foot dis_flex">
                    <div className="left">
                        <div>退还押金<b>￥<em>{info.rent}</em></b></div>
                    </div>
                    <p><React.$Vant.Button nativeType='submit' type='info' block>
                        还设备
                    </React.$Vant.Button></p>
                </div>
            </React.$Vant.Form>
        </>
    )
}