import React from 'react';
import Tabbar from '@/components/tabbar';
// import './index.scss';

export default function Categoryindex() {
    const [categorylist, setCategorylist] = React.useState([])
    const [rotation, setRotation] = React.useState([
        {
            image_text: "https://img.yzcdn.cn/vant/cat.jpeg"
        }
    ])
    // 是否已加载完成，加载完成后不再触发load事件
    const [finished, setFinished] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    // 页码
    const [page, setPage] = React.useState(1)

    const getCategoryIndexData = async () => {
        let result = await React.$Api.categoryIndex({ page: page })
        if (result) {
            if (result.code == 1) {
                let data = result.data
                let list = [...categorylist, ...data.categorylist.data]
                setRotation(data.rotation)
                setCategorylist(list)
                setPage(page + 1)
                setTimeout(() => {
                    setFinished(false)
                    setLoading(false)
                }, 2000)
            } else {
                setFinished(true)
                React.$Vant.Toast.info(result.msg)
                return false
            }
        }
    }

    const onRefresh = () => {
        setCategorylist([])
        setPage(1)
        setFinished(false)
        return new Promise(resolve => {
            setTimeout(() => {
                getCategoryIndexData()
                React.$Vant.Toast.info('刷新成功')
                resolve(true)
            }, 1000)
        })
    }

    // 进来就加载
    const load = () => {
        setFinished(true)
        setLoading(true)
        getCategoryIndexData()
    }
    const Rander = () => {
        return (
            <React.$Vant.Swiper autoplay={2000} style={{ height: '210px' }}>
                {
                    rotation.map((item, index) => {
                        return (
                            <React.$Vant.Swiper.Item key={index}>
                                <React.$Vant.Image fit="cover" src={item.image_text} />
                            </React.$Vant.Swiper.Item>
                        )
                    })
                }
            </React.$Vant.Swiper>
        )
    }

    return (
        <>
            {/* 轮播 */}
            <React.$Vant.Swiper autoplay={2000} style={{ height: '210px' }}>
                {
                    rotation.map((item, index) => {
                        return (
                            <React.$Vant.Swiper.Item key={index}>
                                <React.$Vant.Image fit="cover" src={item.image_text} />
                            </React.$Vant.Swiper.Item>
                        )
                    })
                }
            </React.$Vant.Swiper>
            {/* {Rander()} */}


            <div className="lest_xs">
                <ul>
                    <React.$Vant.PullRefresh onRefresh={() => onRefresh(true)}>
                        {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
                        <React.$Vant.List finished={finished} onLoad={load} loading={loading}>
                            {categorylist.map((item, index) => (
                                <li key={index}>
                                    <React.$Router.NavLink to={'/business/category/info?cateid=' + item.id} >
                                        <img src={item.image_text} alt="" />
                                        <p>{item.name}</p>
                                        <p style = {
                                            {
                                                overflow: 'hidden', textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }
                                        }> 相关评论: {
                                            item.description
                                        }</p >
                                        <span>{item.createtime}</span>
                                    </React.$Router.NavLink>
                                </li>
                            ))}
                        </React.$Vant.List>
                    </React.$Vant.PullRefresh>

                </ul>
            </div>
            <div style={{ height: '.75rem' }}></div>
            <Tabbar />
        </>
    )
}