import React from 'react';
export default function register() {
    const [form] = React.$Vant.Form.useForm()
    const navigate=React.$Router.useNavigate()
     const onFinish = async (val) => {
         let result = await React.$Api.register(val);
          if (result.code == 1) {
              React.$Vant.Toast.success({
                  message: result.msg,
                  onClose: () => {
                      navigate('/business/login')
                  }
              })
          } else {
              React.$Vant.Toast.fail(result.msg)
          }

     }
    return ( <>
       (
<>
        <link rel = "stylesheet"
        type = "text/css"
        href = "/assets/css/util.css" ></link>
        
        <link rel = "stylesheet"
        type = "text/css"
        href = "/assets/css/main.css" >
            </link>
             <div className="limiter">
        <div div className = "container-login100"
        style = {
            {
                // backgroundImage: url('images/img-01.jpg'),
            }
        } >
            <div className="wrap-login100 p-t-190 p-b-30">
                        <React.$Vant.Form form={form} onFinish={onFinish}>
                            {/* 默认头像 */}
                    <div className="login100-form-avatar">
                        < img src = "/assets/images/toux.jpg"
                        alt = "AVATAR" />
                    </div>

                    <span className="login100-form-title p-t-20 p-b-45">Hello</span>

                    <React.$Vant.Form.Item
        rules={[{ required: true, message: '请填写正确的手机号码',pattern:/^1[3456789]\d{9}$/ }]}
        name='mobile'
                                label='手机号'>
                                <div className='wrap-input100 validate-input m-b-10'>
                                     <React.$Vant.Input  placeholder = '请输入手机号' />
    </div>
       
      </React.$Vant.Form.Item>
      <React.$Vant.Form.Item
        rules={[{ required: true, message: '请填写密码' }]}
        name='password'
                                label='密码'
                              
                            >
                                <div className='wrap-input100 validate-input m-b-10'>
                                    < React.$Vant.Input placeholder = '请输入密码'
                                    type = 'password' />
                                </div>
        
      </React.$Vant.Form.Item>

                    <div div className = "container-login100-form-btn p-t-10 text-center w-full p-t-25 p-b-230" >
                                <React.$Vant.Button round nativeType='submit' type='primary' block>注 册</React.$Vant.Button>
                    </div>


                    <div className="text-center w-full">
                        <React.$Router.NavLink  to="/business/login" className="txt1">
                            立即登录
                            <i className="fa fa-long-arrow-right"></i>
                        </React.$Router.NavLink>
                    </div>
                </React.$Vant.Form>
            </div>
        </div>
    </div>

 </>
    )
    </>
    )
}