import {
    POST,
    UPLOAD
} from '@/services/request.js'

export default {
    leaseAdd(data = {}) {
        return UPLOAD({
            url: '/lease/add',
            params: data
        })
    },
    leaseIndex(data = {}) {
        return POST({
            url: '/lease/index',
            params: data
        })
    },
    leaseInfo(data = {}) {
            return POST({
                url: '/lease/info',
                params: data
            })
        },
        leaseReceipt(data = {}) {
            return POST({
                url: '/lease/receipt',
                params: data
            })
        },
        express(data = {}) {
            return POST({
                url: '/lease/express',
                params: data
            })
        },
        leaseRecovery(data = {}) {
            return POST({
                url: '/lease/recovery',
                params: data
            })
        },
        leaseRate(data = {}) {
            return POST({
                url: '/lease/rate',
                params: data
            })
    },
    expressInfo(data = {}) {
            return POST({
                url: '/lease/expressinfo',
                params: data
            })
        },
}