import React from 'react';
import { areaList } from '@vant/area-data'
import cookie from 'react-cookies'
export default function LeaseAdd() {
    let navigate = React.$Router.useNavigate();
    const [form] = React.$Vant.Form.useForm()
    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    const [code, setCode] = React.useState([LoginUser.province, LoginUser.city, LoginUser.district])
    const [isShow, setIsShow] = React.useState(false)
    const [region, setRegion] = React.useState(LoginUser.region)
    const [DateShow, setDateShow] = React.useState(false)
    const [product, setProduct] = React.useState(cookie.load('product') ?? {
        id: '',
        rent: '',
        price: '',
        name: ''
    }
    )

    // 天数，10天
    const [day, SetDay] = React.useState(10)

    //10天后的日期
    const [end, SetEnd] = React.useState(new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000))

    // 回显日期
    const [endtime, SetEndTime] = React.useState(`${end.getFullYear()}/${end.getMonth() + 1}/${end.getDate()}`);

    // 不含押金
    const [price, setPrice] = React.useState(0)

    // 含押金
    const [rent, setRent] = React.useState(0)

    React.useEffect(() => {
        total()
    }, [day])

    // 计算总价
    const total = () =>{
        var rent = product.rent ?  product.rent : 0;
        var price = product.price ? product.price: 0;

        // 不含押金
        var prices = (parseFloat(price) * day).toFixed(2)
        setPrice(prices)

        // 含押金
        var rents = (parseFloat(rent) + parseFloat(prices)).toFixed(2);
        setRent(rents)
    }

    // 确认选择地区
    const ChangeRegion = (val, options) => {
        setIsShow(false)
        let [province, city, district] = options
        let region = '';
        if (province) {
            LoginUser.province = province.value
            region = province.text + '-';
        }
        if (city) {
            LoginUser.city = city.value
            region += city.text + '-';
        }
        if (district) {
            LoginUser.district = district.value
            region += district.text;
        }
        setRegion(region)
    }

    // 确认时间
    const ChangeDate = (val) =>{
        setDateShow(false)
        // 结束时间
        SetEndTime(`${val.getFullYear()}/${val.getMonth() + 1}/${val.getDate()}`)
        // 更改默认时间
        SetEnd(val)
        // 当前日期
        var now = new Date()
        //相差天数
        var time = val - now  // 时间戳

        var days = Math.ceil(time / (1000 * 60 * 60 * 24))

        SetDay(days)

        total()
    }

    // 表单提交
    const onFinish = async(values) => {
        let data = {
            busid: LoginUser.id,
            proid: product.id,
            price: rent,
            endtime: endtime,
            address: values.address,
            card: values.card[0].file,
            province: LoginUser.province,
            city: LoginUser.city,
            district: LoginUser.district
        }
        if (day <= 7) {
            React.$Vant.Toast.fail('租的日期不能低于7天');
            return false;
        }
        let result = await React.$Api.leaseAdd(data)
         if (result && result.code == 1) {
             React.$Vant.Toast.success({
                 message: result.msg,
                 onClose: () => {
                     navigate('/lease/index')
                 }
             })
         } else {
             React.$Vant.Toast.fail(result.msg)
         }
    }

    return (
        <>
            <React.$Vant.NavBar
                title="租赁"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />

            {/* 租赁页面 */}
            <React.$Vant.Form
                form={form}
                onFinish={onFinish}
            >
                {/* 租赁商品 */}
                <React.$Vant.Form.Item
                    isLink
                    name="name"
                    label='租赁商品'
                    rules={[{ required: true, message: '请选择商品' }]}
                    onClick={() => navigate('/product/index?action=add')}
                    initialValue={product.name}
                >
                    <React.$Vant.Input readOnly placeholder="请选择商品" />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写押金' }]}
                    name='rent'
                    label='押金'
                    initialValue={product.rent}
                >
                    <React.$Vant.Input placeholder='请输入押金' readOnly />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写日供租金' }]}
                    name='rent_price'
                    label='日供'
                    initialValue={product.price}
                >
                    <React.$Vant.Input placeholder='请输入日供租金' readOnly />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写昵称' }]}
                    name='nickname'
                    label='昵称'
                    initialValue={LoginUser.nickname}
                >
                    < React.$Vant.Input placeholder = '请输入昵称' readOnly />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写手机号码' }]}
                    name='mobile'
                    label='手机号码'
                    initialValue={LoginUser.mobile}
                >
                    < React.$Vant.Input placeholder = '请输入手机号码' readOnly />
                </React.$Vant.Form.Item>

                {/* 地区 */}
                <React.$Vant.Form.Item
                    isLink
                    label='地区选择'
                    onClick={() => setIsShow(true)}
                >
                    <React.$Vant.Input value={region} readOnly placeholder="请选择地区" />
                </React.$Vant.Form.Item>

                {/* 城市组件信息 */}
                <React.$Vant.Popup visible={isShow} onClose={() => setIsShow(false)} position='bottom'>
                    <React.$Vant.Area
                        value={code}
                        areaList={areaList}
                        onConfirm={ChangeRegion}
                        onCancel={() => setIsShow(false)}
                    >
                    </React.$Vant.Area>
                </React.$Vant.Popup>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写详细地址' }]}
                    name='address'
                    label='详细地址'
                >
                    <React.$Vant.Input placeholder='请输入详细地址' />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    isLink
                    label='租用时间'
                    onClick={() => setDateShow(true)}
                >
                    <React.$Vant.Input value={endtime} readOnly />
                </React.$Vant.Form.Item>

                <React.$Vant.Calendar
                    showConfirm={false}
                    visible={DateShow}
                    defaultValue={end}
                    onConfirm={ChangeDate}
                    onClose={() => setDateShow(false)}
                />

                {/* 证件 */}
                <React.$Vant.Form.Item
                    name='card'
                    label='上传证件'
                    rules={[{ required: true, message: '请上传有效证件,例如身份证' }]}
                >
                    <React.$Vant.Uploader maxCount={1}/>
                </React.$Vant.Form.Item>

                <div className="zy_goods_foot dis_flex">
                    <div className="left">
                        <div>{day}天<b>￥<em>{price}</em></b></div>
                        <div className="tou_d">含押金￥{rent}</div>
                    </div>
                    <p><React.$Vant.Button nativeType='submit' type='danger' block>
                        立即申请
                    </React.$Vant.Button></p>
                </div>
            </React.$Vant.Form>

        </>
    )
}