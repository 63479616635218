import React from 'react';
import cookie from 'react-cookies'
import { WapHomeO } from '@react-vant/icons'
import {
    StarO,Star
} from '@react-vant/icons';
export default function ProductInfo() {
    let navigate = React.$Router.useNavigate();
    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
    // 初始化路由参数
    const [proid, setProid] = React.useState(searchParams.get("proid"))

    const [info, setInfo] = React.useState({
        thumbs_cdn: []//因为里面嵌了一个数组，定义的时候需要里面写一个空数组将结构体现出来
    })

    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    const collectFun = async () => {
        console.log(111);
        let result = await React.$Api.productCollect({ proid: proid, busid: LoginUser.id });
        if (result && result.code == 1) {
            React.$Vant.Toast.success(result.msg)
            getInfoData()
        } else {
            React.$Vant.Toast.fail(result.msg)
        }

    }
    const getInfoData = async () => {
        console.log(proid);
        let result = await React.$Api.productInfo({ proid: proid,busid:LoginUser.id })
        if (result && result.code == 1) {
            setInfo(result.data)
        }
    }
    const Collect = () =>{
        if (!info.collect_status) {
            return(<React.$Vant.ActionBar.Icon
                        icon={<StarO color='red' />}
                        text='收藏'
                        onClick = {collectFun}
                    />)
        } else {
            return(<React.$Vant.ActionBar.Icon
                        icon={<Star color='red' />}
                        text='已收藏'
                        onClick = {collectFun}
                    />)
        }
    }
    const toLeaseAdd = () => {
        cookie.save('product', info);
        navigate('/lease/add');
    }
    React.useEffect(() => {
        getInfoData()
    }, [])

    return (
        <>
            <React.$Vant.NavBar
                title="商品详情"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />
            {/* 商品轮播 */}
            <React.$Vant.Swiper autoplay={2000} style={{ height: '290px' }}>
                {
                    info.thumbs_cdn.map((item, index) => {
                        return (
                            <React.$Vant.Swiper.Item key={index}>
                                <React.$Vant.Image fit="cover" src={item} />
                            </React.$Vant.Swiper.Item>
                        )
                    })
                }
            </React.$Vant.Swiper>
            {/* 商品详情 */}
            <div className="top_title">
                <h2>{info.name}</h2>
                <div className="money">
                    <h3>￥{info.rent_price}</h3>
                    <p>押金:￥{info.rent}</p>
                </div>
                <div className="youhuiq common">
                    <div className="text">
                        <h2>保障</h2>
                        <div className="tb">
                            <div className="tis_fuwu">
                                <ul>
                                    <li className="no">保障</li>
                                    <li>保障</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="youhuiq common common_no">
                    <div className="text">
                        <h2>配送</h2>
                        <div className="tb">
                            送货上门
                        </div>
                    </div>
                </div>
            </div>
            {/* 商品详情内容 */}
            <div className="rem2"></div>

            <div className="xiang_qing_xp">
                {/* 覆盖DOM节点的innerHTML属性，并在内部显示传递的HTMl */}
                <div className="img_t" dangerouslySetInnerHTML={{ __html: info.content }}></div>
            </div>

            <div style={{ height: '1rem' }}></div>

            <div className="zy_goods_foot dis_flex">
                <React.$Vant.ActionBar>
                    <React.$Vant.ActionBar.Icon
                        icon={<WapHomeO color='red' />}
                        text='首页'
                        onClick = {
                            () => {
                                navigate('/')
                            }
                        }
                    />
                    {/* <React.$Vant.ActionBar.Icon
                        icon={<StarO color='red' />}
                        text='收藏'
                        onClick = {collectFun}
                    /> */}
                    <Collect />
                    <React.$Vant.ActionBar.Button
                        type='danger'
                        text='立即租赁'
                        onClick={toLeaseAdd}
                    />
                </React.$Vant.ActionBar>
            </div>
        </>
    )
}