import ProductIndex from '@/components/product/index';
import ProductInfo from '@/components/product/info';
export default [{
        path: 'index',
        component: ProductIndex
    },
    {
        path: 'info',
        component: ProductInfo
    },
]