// import { Route, Routes, Outlet } from 'react-router-dom';
// import Login from '../login';
// import User from '../user';
// import Home from '../home'
// const RoutersList = () => {
//     return (
//         <Routes>
//             <Route path='/' element={<Home />} />
//             <Route path='/login' element={<Login />} />
//             <Route path='/user' element={<User />} />
//         </Routes>
//     )
// }
// export default RoutersList;

import React from 'react'
import AuthLoginComponent from '../services/auth'
import {
    Routes,
    Route,
    Outlet
} from 'react-router-dom'
import Home from '../components/home'

// 引入每个目录下面的index.js文件
const ModulesFile = require.context('./', true, /index.js$/)

// 总路由集合
const RouterMap = []

//对引入的文件进行循环
ModulesFile.keys().reduce((modules, modulePath) => {
    // 路由模块名称   文件夹/index  user/index
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, '$1')
    console.log('module>>>', modules);
    console.log('modulePath>>>>', modulePath);
    //不包含当前index.js 文件
    if (ModuleName !== 'index') {
        //路由列表
        const ModuleList = ModulesFile(modulePath)

        //追加到数组
        RouterMap.push(...ModuleList.default)
    }

    return RouterMap;

}, {})

const RouterList = () => {

        return ( <>
            <Routes>

            {
                /* 默认警醒到首磁页*/ } <Route path = "/"
                    element={<Home />}> </Route>
                {/* <Route path = "/" element = {<> < Outlet /> </>}> */}
                {
                    //item.component
                    //AuthLoginComponent组件是用于检验用户是否登录
                RouterMap.map((item, index) => {
                            return ( <Route key = {index}
                                path = {
                                    item.path
                                }
                                element={<AuthLoginComponent auth={item.auth} component={item.component} />
                                } > {
                                    item.children && item.children.map((child, idx) => < Route key = {
                                            idx
                                        }
                                        path = {
                                            child.path
                                        }
                                        //child.component
                                        element = {
                                            <AuthLoginComponent auth={child.auth} component={child.component} />
                                        } > </Route>)
                                    } </Route>
                                )
                            })
                    } 
                    {/* </Route> */}
                    </Routes> </>
            )
        }

        export default RouterList;