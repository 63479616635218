// 引入每个目录下面的index.js文件
const ModulesFile = require.context('./', true, /index.js$/)

// 接口集合
var Apilist = {}

//对引入的文件进行循环
ModulesFile.keys().reduce((modules, modulePath) => {
    //文件名
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, '$1')

    //不包含当前index.js 文件
    if (ModuleName !== 'index') {
        //请求列表
        const ModuleList = ModulesFile(modulePath)

        //合并对象 循环多次合并
        Apilist = Object.assign(Apilist, ModuleList.default)
    }

    return Apilist;

}, {})

export default Apilist