import React from 'react';
import Tabbar from '@/components/tabbar';
import { Close } from '@react-vant/icons'
import cookie from 'react-cookies'

export default function Index() {
     const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ? cookie.load('LoginUser') : {
                 avatar: '',
                 nickname: ''
     })
    const navigate=React.$Router.useNavigate()
    const logout = () => {
        React.$Vant.Dialog.confirm({
                message: '确认退出登录吗？',
            })
            .then(() => {
                cookie.remove('LoginUser')
                navigate('/business/login')
            })
            .catch(() => {
                console.log('catch')
            })
    }
    const [info, setInfo] = React.useState([]);
    const getCountData = async () => {
        let result = await React.$Api.count();
        if (result && result.code == 1) {
            setInfo(result.data);
        }
    }
    React.useEffect(() => {
        getCountData();
    },[])
    return (
        <>
            <div className="my_kuang">
                <div className="bj_img">
                    <img className="beij_s" src="/assets/images/my_beij.jpg" alt="" />
                    <div className="nimetou_gaib">
                        <div className="toux_hou">
                            <img id="Dfgrg" style={{ height: '1.1rem' }} src={LoginUser.avatar} alt="" />
                            <input id="tupian_Sc" type="file" />
                        </div>
                        <div className="mingz">
                            <h2>{LoginUser.nickname}</h2>
                        </div>
                    </div>
                </div>
                <div className="div_bx_k">
                    <div className="neir_Ef">
                        <div className="yverjif">
                            <ul>
                                <li><h2>{ info.cateCount}</h2><p>收藏文章</p></li>
                                <li><a href="my_cs.html"> <h2>{ info.proCount}</h2><p>收藏书籍</p></a></li>
                                <li><h2>{ info.orderCount}</h2><p>租赁数量</p></li>
                            </ul>
                        </div>
                        <div className="list_index_my">
                            <div className="my_dind">
                                <div className="bt">
                                    <React.$Router.NavLink to="/business/profile">
                                        <h3><img src="/assets/images/my_x_01.png" />基本资料</h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </React.$Router.NavLink>
                                </div>
                            </div>
                            <div className="my_dind">
                                <div className="bt">
                                    <React.$Router.NavLink to="/lease/index">
                                        <h3><img src="/assets/images/my_x_01.png" />我的订单</h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </React.$Router.NavLink>
                                </div>
                            </div>
                            <div className="my_dind">
                                <div className="bt">
                                    <React.$Router.NavLink to="/business/record">
                                        <h3><img src="/assets/images/my_x_01.png" />租赁记录</h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </React.$Router.NavLink>
                                </div>
                            </div>
                            <div className="my_dind">
                                <div className="bt">
                                    <React.$Router.NavLink to="/business/about">
                                        <h3><img src="/assets/images/my_x_02.png" />关于我们</h3>
                                        <div className="right">
                                            <img src="/assets/images/right_jiant.png" />
                                        </div>
                                    </React.$Router.NavLink>
                                </div>
                            </div>
                            {/* <React.$Router.NavLink to="/business/login"> */}
                                <React.$Vant.Cell onClick={logout} title='退出登陆' center size="large" icon={<Close style={{ fontSize: '2em' }} color='#ffc0cb' />} isLink />
                            {/* </React.$Router.NavLink> */}
                        </div>
                        <div style={{ height: '.9rem' }}></div>
                    </div>

                </div>
            </div>
            <div style={{ height: '.75rem' }}></div>

            <div className="foot_menu">
                <Tabbar />
            </div>
        </>
    )
}