import React from 'react';
import cookie from 'react-cookies'
export default function LeaseIndex() {
    let navigate = React.$Router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    const [list, setList] = React.useState([])
    const [statuslist, setStatuslist] = React.useState([
        {
            id: 0,
            name: '全部'
        },
        {
            id: 1,
            name: '已下单'
        },
        {
            id: 2,
            name: '已发货'
        },
        {
            id: 3,
            name: '已收货'
        },
        {
            id: 4,
            name: '已归还'
        },
        {
            id: 5,
            name: '未评价'
        },
        {
            id: 6,
            name: '已完成'
        }
    ])
    const [status, setStatus] = React.useState(0)

    const LeaseIndexData = async () => {
        let result = await React.$Api.leaseIndex({ busid: LoginUser.id, status: status })
        if (result && result.code == 1) {
            setList(result.data)
        }
    }

    React.useEffect(() => {
        LeaseIndexData()
    }, [status])

    const Buttons = (val) => {
        let item = val.item
        if (item.status == '2') {
            return (
                <React.$Vant.Button size="mini" round type='info' onClick={() => HandePages(item, 'receipt')}>
                    确认收货
                </React.$Vant.Button>
            )
        } else if (item.status == '3') {
            return (
                <React.$Vant.Button size="mini" round type='warning' onClick={() => HandePages(item, '/lease/recovery')}>
                    归还商品
                </React.$Vant.Button>
            )
        } else if (item.status == '5') {
            return (
                <React.$Vant.Button size="mini" round type='info' onClick={() => HandePages(item, '/lease/comment')}>
                    订单评价
                </React.$Vant.Button>
            )
        }
    }

    // 页面跳转
    const HandePages = (item, statte) => {
        if (statte == 'receipt') {
            React.$Vant.Dialog.confirm({
                message: '是否确认收货？',
            })
                .then(async() => {
                    let result = await React.$Api.leaseReceipt({ busid: LoginUser.id, leaseid: item.id })
                    if (result && result.code == 1) {
                        LeaseIndexData()
                        React.$Vant.Toast.success({
                            message: result.msg,
                        })
                    } else {
                        React.$Vant.Toast.fail(result.msg)
                    }
                })
                .catch(() => { })
            return false;
        }
        navigate(statte + '?leaseid=' + item.id)
    }

    return (
        <>
            <React.$Vant.NavBar
                title="我的订单"
                leftText=""
                onClickLeft={() => navigate('/business/index')}
            />

            {/* 订单状态 */}
            <React.$Vant.Tabs sticky active={status} color="#f44336" onClickTab={(val) => setStatus(val.name)}>
                {
                    statuslist.map(item => (
                        <React.$Vant.Tabs.TabPane key={item.id} title={item.name} name={item.id}>
                        </React.$Vant.Tabs.TabPane>
                    ))
                }
            </React.$Vant.Tabs>

            {/* 列表内容 */}
            {
                list.map((item) => {
                    return (
                        <React.$Vant.ProductCard
                            key={item.id}
                            num={
                                <>
                                    <React.$Vant.Tag plain type="danger">
                                        {item.status_text}
                                    </React.$Vant.Tag>
                                </>
                            }
                            price={item.price}
                            desc={
                                <>
                                    <div>押金: {item.rent}</div>
                                </>
                            }
                            tags={
                                <>
                                    <div>日租: {item.product.rent_price}</div>
                                </>
                            }
                            title={item.product.name}
                            thumb={item.product.thumb_text}
                            footer={
                                <div style={{ marginTop: '10px' }}>
                                    <React.$Vant.Button size="mini" type='primary' round style={{ marginRight: 2 }} onClick={() => HandePages(item, '/lease/info')}>
                                        订单详情
                                    </React.$Vant.Button>

                                    <Buttons item={item} />

                                    {
                                        item.expcode &&
                                        <React.$Router.NavLink to={"/lease/express?leaseid=" + item.id}>
                                            <React.$Vant.Button size="mini" round type='danger'>
                                            物流详情
                                            </React.$Vant.Button>
                                        </React.$Router.NavLink>
                                        
                                    }
                                </div>
                            }
                        />
                    )
                })
            }
        </>
    )
}