import './App.css';
import React, { useEffect } from 'react';
import { POST } from './services/request';
import { Button } from 'react-vant';
function App() {
  useEffect(() => {
    async function getData() {
      let result = await POST({
       url:'admin/bind',
     })
    } 
    getData();
  },[])
  return (
    <div className="App">
      <div>
        首页
        <Button type='primary'>Primary</Button>
      </div>
    </div>
  );
}

export default App;
