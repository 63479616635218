import {
    POST,
    UPLOAD
} from '@/services/request.js'

export default {
    register(data = {}) {
        return POST({
            url: '/business/register',
            params: data
        })
    },
    login(data = {}) {
        return POST({
            url: '/business/login',
            params: data
        })
    },
    profile(data = {}) {
        return UPLOAD({//有头像上传，需要使用UPLOAD，否则文件上传不了，写的时候的保证前端的数据传过去，后台先不着急写
            url: '/business/profile',
            params: data
        })
    },
    collect(data = {}) {
        return POST({
            url: '/business/collect',
            params: data
        })
    },
    count(data = {}) {
        return POST({
            url: '/business/count',
            params: data
        })
    },
    record(data = {}) {
        return POST({
            url: '/business/record',
            params: data
        })
    },
}