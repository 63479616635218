import React from 'react'
import cookie from 'react-cookies'

export default function Record() {
    const navigate = React.$Router.useNavigate();
    // const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {});
    const [LoginUser, setLoginUser] = React.useState(cookie.load("LoginUser") ?? {})
    const [info, setInfo] = React.useState([]);
    const getRecordData = async () => {
        let result = await React.$Api.record({ busid: LoginUser.id });
        if (result && result.code == 1) {
            setInfo(result.data);
        }
    }
    React.useEffect(() => {
        getRecordData();
    }, [])
    const Record = () => {
        return info.map((item, index) => {
            return (< React.$Vant.ProductCard key={item.id}
                price={item.total}
                desc={item.content}
                title={"消费时间" + item.record_time} /> )
        })
    }
    //  () => navigate('/business/index')
    return (
        <>
             <React.$Vant.NavBar
             title = "消费记录"
             leftText = ""
             onClickLeft = {
                 () => navigate('/business/index')
             } />
            
            {/* 消费记录详情 */}
            <Record/>
        </>
    )
}