import React from 'react';
import cookie from 'react-cookies'
export default function LeaseInfo() {
    let navigate = React.$Router.useNavigate();
    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
    // 初始化路由参数
    const [leaseid, setLeaseid] = React.useState(searchParams.get("leaseid"))
    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    const [info, setInfo] = React.useState({
        product: {},
        bussiness: {}
    })

    const getInfoData = async () => {
        let result = await React.$Api.leaseInfo({ leaseid: leaseid, busid: LoginUser.id })
        if (result && result.code == 1) {
            setInfo(result.data)
        }
    }

    React.useEffect(() => {
        getInfoData()
    }, [])

    const Expressquery = () => {
        if (info.expcode) {
            return (
                <>
                    <li>物流公司名称：<span>{info.expid_text}</span></li>
                    <li>物流单号：<span>{info.expcode}号</span></li>
                </>
            )
        }
    }

    const Rate = () => {
        if (info.rate) {
            return (
                <>
                    <li>评分：<span>{info.rate}</span></li>
                    <li>评价内容：<span>{info.comment}</span></li>
                </>
            )
        }
    }

    //联系客服
    const onTel = () => {
        window.location.href=`tel:${info.mobile}`
    }

    return (
        <>
            <React.$Vant.NavBar
                title="订单详情"
                leftText=""
                onClickLeft={() => navigate('/lease/index')}
            />

            {/* 订单详情 */}
            <div className="zy_module_content" style={{ paddingTop: 0 }}>
                <div className="swiper-container vip_user_order">
                    <div className="swiper-slide">
                        <ul>
                            <li style={{ borderBottom: 'none' }}>
                                <div className="vip_order_goods">
                                    <h3>
                                        <a href="my_dd_xq.html">
                                            <i><img src={info.product.cover_cdn} /></i>
                                            <div style={{
                                                float: 'right', marginLeft: '2.5%',
                                                width: '70%', fontSize: '.22rem', color: '#999', lineHeight: '25px'
                                            }}>
                                                <div>{info.product.name}</div>
                                                <div>
                                                    <em>押金：</em>
                                                    <em>{info.rent}</em>
                                                </div>
                                                <div>
                                                    <em>总价：</em>
                                                    <em>{info.price}</em>
                                                </div>
                                                <div>
                                                    <em>结束时间：</em>
                                                    <em>{info.endtime_text}</em>
                                                </div>
                                                <div>
                                                    <em>订单状态：</em>
                                                    <em>{info.status_text}</em>
                                                </div>
                                            </div>
                                        </a>
                                    </h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="lianxi_ge">
                <a onClick={onTel}>联系客服</a>
            </div>
            <div className="rem2"></div>
            <div className="xiangq_xiaox">
                <ul>
                    <li>联系人：<span>{info.bussiness.nickname}</span></li>
                    <li>性别：<span>{info.bussiness.gender_text}</span></li>
                    <li>联系电话：<span>{info.bussiness.mobile}</span></li>
                    <li className="no">支付方式：<span>在线支付</span></li>
                    {/* 物流信息，评论 */}
                    <Expressquery />
                    <Rate/>
                </ul>
            </div>
            <div className="rem2"></div>
            <div className="nimen_er">
                <p>证件照片</p>
                <div style={{ width: '100%', height: '150px' }}>
                    <img src={info.card_text} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
            </div>
            <div className="rem2"></div>
            <div className="xq_money">
                <p>押金<span className="color">￥{info.rent}</span></p>
                <p>租金<span>+{(info.price - info.rent).toFixed(2)}</span></p>
                <div>
                    <p><span>合计：<b className="color">￥{info.price}</b></span></p>
                </div>
            </div>
        </>
    )
}