import React from 'react';
import cookie from 'react-cookies'
import { StarO, Star } from '@react-vant/icons';

export default function Categoryinfo() {
    let navigate = React.$Router.useNavigate();
    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
    // 初始化路由参数
    const [cateid,setCateid] = React.useState(searchParams.get("cateid"))

    const [info,setInfo] = React.useState({})
    const [prev,setPrev] = React.useState({})
    const [next,setNext] = React.useState({})

    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )

    const getInfoData = async () => {
        let result = await React.$Api.categoryInfo({cateid: cateid, busid: LoginUser.id})
        if(result && result.code == 1)
        {
            setInfo(result.data.info)
            setPrev(result.data.prev)
            setNext(result.data.next)
        }
    }

    React.useEffect(() => {
        getInfoData()
    }, [cateid])

    const CahngeCollect = async() =>{
        if(Object.values(LoginUser).length <= 0)
        {
            React.$Vant.Toast.info({
                message: '未登陆，请先去登陆',
                onClose: () => {
                    navigate('/business/login')
                }
            })
            return false;
        } 

        let result = await React.$Api.collect({cateid: cateid, busid: LoginUser.id})

        if(result && result.code == 1)
        {
            React.$Vant.Toast.success(result.msg)
            getInfoData()
        } else {
            React.$Vant.Toast.fail(result.msg)
        }

    }

    const Prev = () =>{
        if(prev){
            return(
                <p>上一篇： <a onClick={() => setCateid(prev.id)}>{prev.name}</a></p>
            )
        } else {
            return(<p>上一篇： 暂无内容</p>)
        }
    }

    const Next = () =>{
        if(next){
            return(
                <p>下一篇：  <a onClick={() => setCateid(next.id)}>{next.name}</a></p>
            )
        } else {
            return(<p>下一篇： 暂无内容</p>)
        }
    }

    const Collect = () =>{
        if(info.collect_status){
            return(<a onClick={CahngeCollect}> <Star  fontSize="25px" color="#ffb2c3"/>收藏</a>)
        } else {
            return(<a onClick={CahngeCollect}> <StarO  fontSize="25px"/>未收藏</a>)
        }
    }

    return (
        <>
            <React.$Vant.NavBar
                title="详情页"
                leftText=""
                onClickLeft={() => navigate('/business/category/index')}
            />

            <div className="xs_Xiangq">
                <div className="bt">
                    <h2>{info.name} </h2>
                    <div className="text">
                        <span></span>
                        <span>{info.createtime}</span>
                    </div>
                </div>
                <div className="wom_de">
                    {info.description}
                </div>
                <div className="niming_sq niming_sq_xs_xq">
                    <Collect/>
                </div>
            </div>

            <div className="rem2"></div>
            <div className="shangxia_piab">
                <Prev/>
                <Next/>
            </div>
        </>
    )
}