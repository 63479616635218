import { Bookmark, WapHome, More, Manager } from '@react-vant/icons'
import React from 'react';

export default function Tabbar() {

    const [name,setName] = React.useState('/')
    let navigate = React.$Router.useNavigate();
     // 获取当前页面的地址
     let location = React.$Router.useLocation();
    const onTabbarChange = (val) =>{
        setName(val)
        navigate(val)
    }

    // 默认设置底部选中样式
    React.useEffect(() => {
        setName(location.pathname)
    }, [name])

    return (
        <>
            <div className='demo-tabbar'>
                <React.$Vant.Tabbar value={name} activeColor='#f44336' inactiveColor='#000' onChange={(e) => onTabbarChange(e)}>
                    <React.$Vant.Tabbar.Item name='/' icon={<WapHome  />}>
                        首页
                    </React.$Vant.Tabbar.Item>
                    <React.$Vant.Tabbar.Item name = '/business/consultation'
                    icon = {
                        < More />
                    }>
                        咨询
                    </React.$Vant.Tabbar.Item>
                    < React.$Vant.Tabbar.Item name = '/business/category/index'
                    icon = {< Bookmark />}>
                        交流吧
                    </React.$Vant.Tabbar.Item>
                    <React.$Vant.Tabbar.Item name='/business/index' icon={<Manager  />}>
                        我的
                    </React.$Vant.Tabbar.Item>
                </React.$Vant.Tabbar>
            </div>
        </>
    )
}