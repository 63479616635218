import React from 'react';
import cookie from 'react-cookies'
import { Fire, FireO } from '@react-vant/icons'
export default function LeaseComment() {
    let navigate = React.$Router.useNavigate();
    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
    // 初始化路由参数
    const [leaseid, setLeaseid] = React.useState(searchParams.get("leaseid"))
    const [info, setInfo] = React.useState({
        product: {}
    })
    const [form] = React.$Vant.Form.useForm()

    const getData = async () => {
        let result = await React.$Api.leaseInfo({ leaseid: leaseid, busid: LoginUser.id })

        if (result && result.code == 1) {
            setInfo(result.data)
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    // 表单提交
    const onFinish = async (values) => {
        let data = {
            leaseid: leaseid,
            busid: LoginUser.id,
            rate: values.rate,
            comment: values.comment
        }

        let result = await React.$Api.leaseRate(data);
        if (result && result.code == 1) {
            React.$Vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/lease/index')
                }
            })
        } else {
            React.$Vant.Toast.fail(result.msg)
        }
    }

    return (
        <>
            <React.$Vant.NavBar
                title="订单评价"
                leftText=""
                onClickLeft={() => navigate('/lease/index')}
            />
            <div className="zy_module_content" style={{ paddingTop: 0 }}>
                <div className="swiper-container vip_user_order">
                    <div className="swiper-slide">
                        <ul>
                            <li style={{ borderBottom: 'none' }}>
                                <div className="vip_order_goods">
                                    <h3>
                                        <a href="my_dd_xq.html">
                                            <i><img src={info.product.cover_cdn} /></i>
                                            <div style={{
                                                float: 'right', marginLeft: '2.5%',
                                                width: '70%', fontSize: '.22rem', color: '#999', lineHeight: '25px'
                                            }}>
                                                <div>
                                                    <em>厂家联系人：</em>
                                                    <em>{info.contact}</em>
                                                </div>
                                                <div>
                                                    <em>厂家联系电话：</em>
                                                    <em>{info.mobile}</em>
                                                </div>
                                                <div>
                                                    <em>归还地址：</em>
                                                    <em>{info.address}</em>
                                                </div>
                                            </div>
                                        </a>
                                    </h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="lianxi_ge">
                <a href="#">联系客服</a>
            </div>

            <React.$Vant.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.$Vant.Button round nativeType='submit' type='info' block>
                            提交评论
                        </React.$Vant.Button>
                    </div>
                }
            >
                <React.$Vant.Form.Item
                    label='评分'
                    rules={[{ required: true, message: '请填写评分' }]}
                    name='rate'
                >
                    <React.$Vant.Rate icon={<Fire />} voidIcon={<FireO />} size="30" />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写评分内容' }]}
                    name='comment'
                    label='评分内容'
                >
                    <React.$Vant.Input.TextArea rows={3} autoSize maxLength={120} showWordLimit />
                </React.$Vant.Form.Item>

            </React.$Vant.Form>
        </>
    )
}