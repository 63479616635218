import Register from '@/components/business/register';
import Login from '@/components/business/login';
import BaseIndex from '@/components/business/index';
import Profile from '@/components/business/profile';
import About from '@/components/business/about';
import Record from '@/components/business/record';
const base = [{
        path: 'register',
        component: Register
    },
    {
        path: 'login',
        component: Login
    },
    {
        path: 'index',
        component: BaseIndex,
        auth:true,//需要校验登录
    },
    {
        path: 'profile',
        component: Profile,
        auth: true, //需要校验登录
    },
     {
         path: 'about',
         component: About,
         auth: true, //需要校验登录
    },
    {
        path: 'record',
        component: Record,
        auth: true, //需要校验登录
    }
]

export default base;