import React from 'react';
import cookie from 'react-cookies'

export default function ProductIndex() {
    let navigate = React.$Router.useNavigate();
    const [keywords, setKeywords] = React.useState('');
    const [list, setList] = React.useState([])

    const ProductIndexData = async () => {
        let result = await React.$Api.productList({ keywords: keywords })
        if (result && result.code == 1) {
            setList(result.data)
        }
    }

    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
   // 初始化路由参数
    const [action, setAction] = React.useState(searchParams.get("action"))
    const toProductInfo = (item) => {
        // 租赁页面
        if (action == 'add') {
            let data = {
                id: item.id,
                rent: item.rent,
                price: item.rent_price,
                name: item.name
            }
            // 产品信息
            cookie.save('product', data)
            navigate('/lease/add');
        } else {
            navigate('/product/info?proid=' + item.id);
        }
    }

    React.useEffect(() => {
        ProductIndexData()
    }, [keywords])

    return (
        <>
            <React.$Vant.NavBar
                title="商品列表"
                leftText=""
                onClickLeft={() => navigate(-1)}
            />
            {/* 搜索框  */}
            <React.$Vant.Search value={keywords} onSearch={setKeywords} placeholder="请输入搜索关键词" />

            <div className="left_kuangs">
                <ul>
                    {
                        list.map((item) => {
                            return (
                                <li key={item.id}>
                                     <a onClick = {
                                         () => toProductInfo(item)}>
                                        <img src={item.cover_cdn} alt="" />
                                        <p>{item.name}</p>
                                        <span>￥{item.price}</span>
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}
