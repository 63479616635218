import React from 'react';
export default function Express() {
    const navigate = React.$Router.useNavigate();
    // 接收路由传参
    let [searchParams] = React.$Router.useSearchParams();
    // 初始化路由参数
    const [leaseid, setLeaseid] = React.useState(searchParams.get('leaseid'));
      const [active, setActive] = React.useState(0);

    const [info, setInfo] = React.useState({
        express: [],
        leaseinfo:[],
    })
    const getExpressData = async () => {
        const result = await React.$Api.expressInfo({ leaseid: leaseid });
        if (result && result.code == 1) {
            setInfo(result.data)
        }
    }
   React.useEffect(() => {
       getExpressData()
   }, [])
    const StepsFn = () => {
        return info.express.map((item, index) => {
            return (
                // <React.$Vant.Steps.Item key={index}>
                //     <h3>{item.status}</h3>
                //     <p>{item.time}</p>
                // </React.$Vant.Steps.Item>
                <React.$Vant.Steps.Item key = {
                    index
                }>
        <h3>【城市】物流状态1 </h3>
        <p>2016-07-12 12:40</p>
      </React.$Vant.Steps.Item>

            )
        })
    }
   
    return (
        <>
        < React.$Vant.NavBar
        title = "物流详情"
        leftText = ""
        onClickLeft = {
            () => navigate('/lease/index')
        } />
        {/* <React.$Vant.Steps direction="vertical">
                    < StepsFn/>
        </React.$Vant.Steps> */}
              <React.$Vant.Steps direction = "vertical"
        active = {active}>
                {/* <StepsFn/> */}
                {
                    info.express.map((item, index) => {
                        return (
                           <React.$Vant.Steps.Item key={index}>
                                <h3>{item.status}</h3>
                               <p>{item.time}</p>
                            </React.$Vant.Steps.Item>
                       )
                   })
                }
    </React.$Vant.Steps>
        </>
    )
}