import {
    POST
} from '@/services/request.js'
export default {
    categoryIndex(data = {}) {
        return POST({
            url: '/category/index',
            params: data
        })
    },
    consultationIndex(data = {}) {
        return POST({
            url: '/category/consultation',
            params: data
        })
    },
     categoryInfo(data = {}) {
         return POST({
             url: '/category/info',
             params: data
         })
     },
}