import React from 'react';
import cookie from 'react-cookies'
import { areaList } from '@vant/area-data'
export default function Profile() {
    let navigate = React.$Router.useNavigate();
    const [form] = React.$Vant.Form.useForm()

    const [LoginUser, setLoginUser] = React.useState(cookie.load('LoginUser') ?? {}
    )
    const [code, setCode] = React.useState([LoginUser.province, LoginUser.city, LoginUser.district])
    const [isShow, setIsShow] = React.useState(false)
    const [region, setRegion] = React.useState(LoginUser.region)

    const ChangeRegion = (val, options) => {
        setIsShow(false)
        let [province, city, district] = options
        let region = '';
        if (province) {
            LoginUser.province = province.value
            region = province.text + '-';
        }
        if (city) {
            LoginUser.city = city.value
            region += city.text + '-';
        }
        if (district) {
            LoginUser.district = district.value
            region += district.text;
        }
        setRegion(region)
    }

    // 表单提交
    const onFinish = async (values) => {
        let data = {
            id: LoginUser.id,
            nickname: values.nickname,
            gender: values.gender,
            email: values.email,
            province: LoginUser.province,
            city: LoginUser.city,
            district: LoginUser.district,
        }

        // 是否修改密码
        if (values.password) {
            data.password = values.password
        }

        // 是否修改头像
        if (values.avatar && values.avatar[0].file) {
            data.avatar = values.avatar[0].file
        }

        let result = await React.$Api.profile(data)
        if (result.code == 1) {
            // 存储用户信息
            cookie.save('LoginUser', result.data)
            React.$Vant.Toast.success({
                message: result.msg,
                onClose: () => {
                    navigate('/business/index')
                }
            })
        } else {
            React.$Vant.Toast.fail(result.msg)
        }
    }

    return (
        <>
            <React.$Vant.NavBar
                title="基本资料"
                leftText=""
                onClickLeft={() => navigate('/business/index')}
            />

            <React.$Vant.Form
                form={form}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <React.$Vant.Button round nativeType='submit' type='primary' block>
                            提交
                        </React.$Vant.Button>
                    </div>
                }
            >
                <React.$Vant.Form.Item
                    name='mobile'
                    label='手机号码'
                    initialValue={LoginUser.mobile}
                >
                    <React.$Vant.Input readOnly />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    rules={[{ required: true, message: '请填写昵称' }]}
                    name='nickname'
                    label='昵称'
                    initialValue={LoginUser.nickname}
                >
                    <React.$Vant.Input placeholder='请输入昵称' />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    name='password'
                    label='密码'
                >
                    <React.$Vant.Input placeholder='请输入密码' type="password" />
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item name='gender' label='性别' initialValue={LoginUser.gender}>
                    <React.$Vant.Radio.Group direction='horizontal'>
                        <React.$Vant.Radio name='0'>保密</React.$Vant.Radio>
                        <React.$Vant.Radio name='1'>男</React.$Vant.Radio>
                        <React.$Vant.Radio name='2'>女</React.$Vant.Radio>
                    </React.$Vant.Radio.Group>
                </React.$Vant.Form.Item>

                <React.$Vant.Form.Item
                    name='email'
                    label='邮箱'
                    initialValue={LoginUser.email ?? ''}
                    rules={[{ type: 'email', message: '请填写正确的邮箱' }]}
                >
                    <React.$Vant.Input placeholder='请输入邮箱' />
                </React.$Vant.Form.Item>

                {/* 地区 */}
                <React.$Vant.Form.Item
                    isLink
                    label='地区'
                    onClick={() => setIsShow(true)}
                >
                    <React.$Vant.Input value={region} readOnly placeholder="请选择地区" />
                </React.$Vant.Form.Item>

                {/* 城市组件信息 */}
                <React.$Vant.Popup visible={isShow} onClose={() => setIsShow(false)} position='bottom'>
                    <React.$Vant.Area
                        value={code}
                        areaList={areaList}
                        onConfirm={ChangeRegion}
                        onCancel={() => setIsShow(false)}
                    >
                    </React.$Vant.Area>
                </React.$Vant.Popup>

                {/* 头像 */}
                <React.$Vant.Form.Item
                    name='avatar'
                    label='头像'
                    initialValue={[
                        {
                            url: LoginUser.avatar,
                        },
                    ]}
                >
                    <React.$Vant.Uploader maxCount={1} />
                </React.$Vant.Form.Item>

            </React.$Vant.Form>
        </>
    )
}