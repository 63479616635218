import React from 'react';
import Tabbar from '@/components/tabbar';

export default function Home() {

    const [carousel, setCarousel] = React.useState([
        {
            id: 1,
            thumb_text: ''
        }
    ])
    const [newlist, setNewlist] = React.useState([
        {
            id: 1,
            thumb_text: ''
        }
    ])
    const [hostlist, setHostlist] = React.useState([])

    const getHomData = async () => {
        let result = await React.$Api.productIndex()
        if (result && result.code == 1) {
            setCarousel(result.data.carousel)
            setNewlist(result.data.newlist)
            setHostlist(result.data.hostlist)
        }
    }

    React.useEffect(() => {
        getHomData()
    }, [])

    return (
        <>
            <React.$Vant.Swiper autoplay={2000} style={{ height: '290px' }}>
                {
                    carousel.map((item, index) => {
                        return (
                            <React.$Vant.Swiper.Item key={index}>
                                <React.$Router.NavLink to={'/product/info?proid=' + item.id}>
                                    <React.$Vant.Image fit="cover" src={item.cover_cdn} />
                                </React.$Router.NavLink>
                            </React.$Vant.Swiper.Item>
                        )
                    })
                }
            </React.$Vant.Swiper>

            <div className="shouye_kuanj">
                <React.$Vant.Grid border={false}>
                    <React.$Vant.Grid.Item>
                        <div className="swiper-slide"><React.$Router.NavLink to="/lease/add"><img src="/assets/images/kj.png" alt="" /> <p>书籍租赁</p></React.$Router.NavLink></div>
                    </React.$Vant.Grid.Item>
                    <React.$Vant.Grid.Item>
                        <div className="swiper-slide"><React.$Router.NavLink to="/lease/index"><img src="/assets/images/kj1.png" alt="" /> <p>我要归还</p></React.$Router.NavLink></div>
                    </React.$Vant.Grid.Item>
                    <React.$Vant.Grid.Item>
                        <div className="swiper-slide"><React.$Router.NavLink to="/product/index"><img src="/assets/images/kj2.png" alt="" /> <p>书籍大全</p></React.$Router.NavLink></div>
                    </React.$Vant.Grid.Item>
                    <React.$Vant.Grid.Item>
                        <div className="swiper-slide"><React.$Router.NavLink to="/business/about"><img src="/assets/images/kj3.png" alt="" /> <p>关于我们</p> </React.$Router.NavLink></div>
                    </React.$Vant.Grid.Item>
                </React.$Vant.Grid>
            </div>

            <div className="chan_p_center">
                <div className="shouye_biaot_k">
                    <a href="list.html">
                        <h2>产品中心</h2>
                        <p>Product Center</p>
                        <img src="/assets/images/jiant.png" alt="" />
                    </a>
                </div>
                <div className="chanp_k_sy">
                    <div className="sjpiue_chanp_list">
                        <React.$Vant.Swiper autoplay={2000}>
                            {
                                newlist.map((item, index) => {
                                    return (
                                        <React.$Vant.Swiper.Item key={index}>
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <React.$Router.NavLink to={'/product/info?proid=' + item.id} className="a_blok">
                                                        < img src = {item.cover_cdn}
                                                        alt = "" />
                                                        <span>查看详情</span>
                                                    </React.$Router.NavLink>
                                                </div>
                                            </div>
                                        </React.$Vant.Swiper.Item>
                                    )
                                })
                            }
                        </React.$Vant.Swiper>
                    </div>
                </div>
                <div className="lest_zx">
                    <ul>
                        <li>
                            <a href="#">
                                <img src="/assets/images/fangh.jpg" alt="" />
                                <p>在线咨询</p>
                            </a>
                        </li>
                        <li>
                            <a href="zn.html">
                                <img src="/assets/images/fangh1.jpg" alt="" />
                                <p>使用指南</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="shouye_biaot_k">
                    <a href="anli.html">
                        <h2>经典书籍</h2>
                        <p>ClassNameic book</p>
                  
                    </a>
                </div>

                <div className="list_color" >
                    <ul>
                        {
                            hostlist.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <React.$Router.NavLink to={'/product/info?proid=' + item.id}>
                                            <img src={item.cover_cdn} alt="" />
                                            <div className="right">
                                                <p>{item.name}</p>
                                                <span>{item.createtime}</span>
                                            </div>
                                        </React.$Router.NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div style={{ height: '.75rem' }}></div>
            <Tabbar />
        </>
    )
}