import LeaseAdd from '@/components/lease/add';
import LeaseIndex from '@/components/lease/index';
import LeaseInfo from '@/components/lease/info';
import LeaseRecovery from '@/components/lease/recovery';
import LeaseComment from '@/components/lease/comment';
import LeaseExpress from '@/components/lease/express';
export default [{
        path: 'add',
        component: LeaseAdd,
        auth: true
    },
    {
        path: 'index',
        component: LeaseIndex,
        auth: true
    },
    {
        path: 'info',
        component: LeaseInfo,
        auth: true
    }, {
        path: 'recovery',
        component: LeaseRecovery,
        auth: true
    }, {
        path: 'comment',
        component: LeaseComment,
        auth: true
    },
     {
         path: 'express',
         component: LeaseExpress,
         auth: true
     },
]