import React from "react";
import cookie from 'react-cookies'
const AuthLogin = () => {
    const navigate = React.$Router.useNavigate();
    React.useEffect(() => {
        const LoginUser = cookie.load('LoginUser') ? cookie.load('LoginUser') : {}
        if (JSON.stringify(LoginUser) === '{}') {
            navigate('/business/login');
            React.$Vant.Toast.fail({
                message:'未登录，请先去登录'
            })
        }
    })
    return false;
}

const AuthLoginComponent = (props) => {
    return (
        <>
            {props.auth && <AuthLogin />}
            { <props.component /> }
        </>
    )
}
export default AuthLoginComponent;